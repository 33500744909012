var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.isLoading)?_c('mcv-loading'):_vm._e(),(_vm.error)?_c('mcv-error-message'):_vm._e(),(_vm.feed)?_c('div',[_vm._l((_vm.feed.articles),function(article,index){return _c('div',{key:index,staticClass:"article-preview"},[_c('div',{staticClass:"article-meta"},[_c('router-link',{attrs:{"to":{name: 'userProfile', params: {slug: article.author.username}}}},[_c('img',{attrs:{"src":article.author.image,"alt":article.author.username}})]),_c('div',{staticClass:"info"},[_c('router-link',{staticClass:"author",attrs:{"to":{
              name: 'userProfile',
              params: {slug: article.author.username},
            }}},[_vm._v(" "+_vm._s(article.author.username)+" ")]),_c('span',{staticClass:"date"},[_vm._v(_vm._s(article.createdAt))])],1),_c('div',{staticClass:"pull-xs-right"},[_c('mcv-add-to-favorites',{attrs:{"is-favorited":article.favorited,"article-slug":article.slug,"favorites-count":article.favoritesCount}})],1)],1),_c('router-link',{staticClass:"preview-link",attrs:{"to":{
          name: 'article',
          params: {
            slug: article.slug,
          },
        }}},[_c('h1',[_vm._v(" "+_vm._s(article.slug)+" ")]),_c('p',[_vm._v(_vm._s(article.description))]),_c('span',[_vm._v("reed more")]),_c('mcv-tag-list',{attrs:{"tag-list":article.tagList}})],1)],1)}),_c('mcv-pagination',{attrs:{"total":_vm.feed.articlesCount,"limit":_vm.limit,"current-page":_vm.currentPage,"url":_vm.baseUrl}})],2):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }