<template>
  <div class="banner">
    <div class="container">
      <h1 class="logo-font">Medium Clone</h1>
      <p>A place to share your knowledge.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'McvBanner',
};
</script>

<style></style>
