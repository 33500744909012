var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.userProfile)?_c('div',{staticClass:"profile-page"},[_c('div',{staticClass:"user-info"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xs-12 col-md-10 offset-md-1"},[_c('img',{staticClass:"user-img",attrs:{"src":_vm.userProfile.image}}),_c('h4',[_vm._v(_vm._s(_vm.userProfile.name))]),_c('p',[_vm._v(" "+_vm._s(_vm.userProfile.bio)+" ")]),_vm._m(0),(_vm.isCurrentUserProfile)?_c('router-link',{staticClass:"btn btn-sm btn-outline-secondary action-btn",attrs:{"to":{
              name: 'settings',
            }}},[_c('i',{staticClass:"ion-gear-a"}),_vm._v("  Edit Profile Settings")]):_vm._e()],1)])])]),_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xs-12 col-md-10 offset-md-1"},[_c('div',{staticClass:"articles-toggle"},[_c('ul',{staticClass:"nav nav-pills outline-active"},[_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",class:{active: _vm.routeName === 'userProfile'},attrs:{"to":{
                  name: 'userProfile',
                  params: {
                    slug: _vm.userProfile.username,
                  },
                }}},[_vm._v(" My Articles ")])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",class:{active: _vm.routeName === 'userProfileFavorites'},attrs:{"to":{
                  name: 'userProfileFavorites',
                  params: {
                    slug: _vm.userProfile.username,
                  },
                }}},[_vm._v("Favorited Articles")])],1)])]),_c('mcv-feed',{attrs:{"api-url":_vm.apiUrl}})],1)])])]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"btn btn-sm btn-outline-secondary action-btn"},[_c('i',{staticClass:"ion-plus-round"}),_vm._v("   Follow USER BUTTON ")])
}]

export { render, staticRenderFns }