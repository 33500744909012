<template>
  <div class="profile-page" v-if="userProfile">
    <div class="user-info">
      <div class="container">
        <div class="row">
          <div class="col-xs-12 col-md-10 offset-md-1">
            <img :src="userProfile.image" class="user-img" />
            <h4>{{ userProfile.name }}</h4>
            <p>
              {{ userProfile.bio }}
            </p>
            <button class="btn btn-sm btn-outline-secondary action-btn">
              <i class="ion-plus-round"></i>
              &nbsp; Follow USER BUTTON
            </button>

            <router-link
              class="btn btn-sm btn-outline-secondary action-btn"
              :to="{
                name: 'settings',
              }"
              v-if="isCurrentUserProfile"
            >
              <i class="ion-gear-a"></i>&nbsp; Edit Profile
              Settings</router-link
            >
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row">
        <div class="col-xs-12 col-md-10 offset-md-1">
          <div class="articles-toggle">
            <ul class="nav nav-pills outline-active">
              <li class="nav-item">
                <router-link
                  class="nav-link"
                  :class="{active: routeName === 'userProfile'}"
                  :to="{
                    name: 'userProfile',
                    params: {
                      slug: userProfile.username,
                    },
                  }"
                >
                  My Articles
                </router-link>
              </li>

              <li class="nav-item">
                <router-link
                  class="nav-link"
                  :class="{active: routeName === 'userProfileFavorites'}"
                  :to="{
                    name: 'userProfileFavorites',
                    params: {
                      slug: userProfile.username,
                    },
                  }"
                  >Favorited Articles</router-link
                >
              </li>
            </ul>
          </div>

          <mcv-feed :api-url="apiUrl"></mcv-feed>

          <!-- <ul class="pagination">
            <li class="page-item active">
              <a class="page-link" href="">1</a>
            </li>
            <li class="page-item">
              <a class="page-link" href="">2</a>
            </li>
          </ul> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState, mapGetters} from 'vuex';
import McvFeed from '@/components/Feed';
import {actionTypes as userProfileActionTypes} from '@/store/modules/userProfile';
import {getterTypes as authGetterTypes} from '@/store/modules/auth';

export default {
  name: 'McvUserProfile',
  components: {McvFeed},
  computed: {
    ...mapState({
      isLoading: (state) => state.userProfile.isLoading,
      error: (state) => state.userProfile.error,
      userProfile: (state) => state.userProfile.data,
    }),
    ...mapGetters({
      currentUser: authGetterTypes.currentUser,
    }),
    isCurrentUserProfile() {
      if (!this.currentUser && !this.userProfile) {
        return false;
      }
      return this.currentUser.username === this.userProfile.username;
    },
    apiUrl() {
      const isFavorites = this.$route.path.includes('favorites');

      return isFavorites
        ? `/articles?favorited=${this.userProfileSlug}`
        : `/articles?author=${this.userProfileSlug}`;
    },
    userProfileSlug() {
      return this.$route.params.slug;
    },
    routeName() {
      // console.log('this.$route', this.$route);
      return this.$route.name;
    },
  },
  methods: {
    getUserProfile() {
      this.$store.dispatch(userProfileActionTypes.getUserProfile, {
        slug: this.userProfileSlug,
      });
    },
  },
  watch: {
    userProfileSlug() {
      this.getUserProfile();
    },
  },
  mounted() {
    this.getUserProfile();
  },
};
</script>

<style></style>
