export const getItem = (key) => {
  try {
    return JSON.parse(window.localStorage.getItem(key));
  } catch (error) {
    console.log('Error getting  form localStorage', error);
  }
};

export const setItem = (key, obj) => {
  try {
    window.localStorage.setItem(key, JSON.stringify(obj));
  } catch (error) {
    console.log('Error getting  form localStorage', error);
  }
};
