<template>
  <div class="article-page">
    <div class="banner">
      <div class="container" v-if="article">
        <h1>{{ article.title }}</h1>
        <div class="article-meta">
          <router-link
            :to="{name: 'userProfile', params: {slug: article.author.username}}"
          >
            <img :src="article.author.image" :alt="article.author.username" />
          </router-link>
          <div class="info">
            <router-link
              :to="{
                name: 'userProfile',
                params: {slug: article.author.username},
              }"
              class="author"
            >
              {{ article.author.username }}
            </router-link>
            <span class="date">{{ article.createdAt }}</span>
          </div>
          <span v-if="isAuthor">
            <router-link
              class="btn btn-sm btn-outline-secondary"
              :to="{name: 'editArticle', params: {slug: article.slug}}"
            >
              <i class="ion-edit"> </i>Edit Article
            </router-link>
            <button
              @click="deleteArticle"
              class="btn btn-sm btn-outline-danger"
            >
              <i class="ion-trash-a"> </i>
              Delete Article
            </button>
          </span>
        </div>
      </div>
    </div>
    <div class="container page">
      <mcv-loading v-if="isLoading" />
      <mcv-error-message v-if="error" :message="error" />
      <div class="row article-content" v-if="article">
        <div class="col-xs-12">
          <div>
            <p>
              {{ article.body }}
            </p>
          </div>
          <mcv-tag-list :tag-list="article.tagList" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {actionTypes as articleActionTypes} from '@/store/modules/article';
import {getterTypes as authGetterTypes} from '@/store/modules/auth';
import {mapState, mapGetters} from 'vuex';

import McvErrorMessage from '@/components/ErrorMessage.vue';
import McvLoading from '@/components/Loading.vue';
import McvTagList from '@/components/TagList.vue';

export default {
  name: 'McvArticle',
  components: {McvErrorMessage, McvLoading, McvTagList},
  computed: {
    ...mapState({
      article: (state) => state.article.data,
      isLoading: (state) => state.article.isLoading,
      error: (state) => state.article.error,
    }),
    ...mapGetters({
      currentUser: authGetterTypes.currentUser,
    }),
    isAuthor() {
      if (!this.currentUser || !this.article) {
        return false;
      }
      return this.currentUser.username === this.article.author.username;
    },
  },
  methods: {
    deleteArticle() {
      // console.log('this route', this.$route)
      this.$store
        .dispatch([articleActionTypes.deleteArticle], {
          slug: this.$route.params.slug,
        })
        .then(() => {});
    },
  },
  mounted() {
    this.$store.dispatch(articleActionTypes.getArticle, {
      slug: this.$route.params.slug,
    });
  },
};
</script>

<style></style>
