<template>
  <div class="error-messages">
    <p>{{ message }}</p>
  </div>
</template>

<script>
export default {
  name: 'McvError',
  props: {
    message: {
      type: String,
      required: false,
      default: 'Something bad happend',
    },
  },
};
</script>

<style></style>
