import axios from 'axios';
import {getItem} from '@/helpers/persistanceStorage';

// axios.defaults.baseURL = 'https://conduit.productionready.io/api'; // old api endpoint
axios.defaults.baseURL = 'https://api.realworld.io/api';

axios.interceptors.request.use(function (config) {
  // Здесь можете сделать что-нибудь с перед отправкой запроса
  const token = getItem('accessToken');
  const authorizationToken = token ? `Token ${token}` : '';
  config.headers.Authorization = authorizationToken;
  return config;
});

export default axios;
