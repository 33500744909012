<template>
  <div id="app">
    <mcv-topbar />
    <router-view />
  </div>
</template>

<script>
import McvTopbar from '@/components/Topbar.vue';
import {actionTypes} from '@/store/modules/auth';

export default {
  name: 'MvcApp',
  components: {McvTopbar},
  mounted() {
    this.$store.dispatch(actionTypes.getCurrentUser);
  },
};
</script>
